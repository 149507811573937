import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  Form,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Button,
} from 'react-bootstrap';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Message from '../components/Message';
import Loader from '../components/Loader';
import {
  useDeliverOrderMutation,
  useGetOrderDetailsQuery,
  useGetPaypalClientIdQuery,
  usePayOrderMutation,
  usePayOrderMobileMutation,
} from '../slices/ordersApiSlice';
import styles from './OrderScreen.module.css';
const promotionCodes = ['freedoll'];

const OrderScreen = () => {
  const { id: orderId } = useParams();
  const [code, setCode] = useState('');
  const [matchCode, setMatchCode] = useState(false);
  const {
    data: order,
    refetch,
    isLoading,
    error,
  } = useGetOrderDetailsQuery(orderId);
  const navigate = useNavigate();
  const [payOrder, { isLoading: loadingPay }] = usePayOrderMutation();
  const [payOrderMobile, { isLoading: loadingPayMobile }] =
    usePayOrderMobileMutation();

  const [deliverOrder, { isLoading: loadingDeliver }] =
    useDeliverOrderMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();

  const {
    data: paypal,
    isLoading: loadingPayPal,
    error: errorPayPal,
  } = useGetPaypalClientIdQuery();

  // useEffect(() => {
  //   navigate('/');
  // }, []);
  useEffect(() => {
    setMatchCode(promotionCodes.find((promotionCode) => code == promotionCode));
    if (!errorPayPal && !loadingPayPal && paypal.clientId) {
      const loadPaypalScript = async () => {
        paypalDispatch({
          type: 'resetOptions',
          value: {
            'client-id': paypal.clientId,
            currency: 'USD',
          },
        });
        paypalDispatch({ type: 'setLoadingStatus', value: 'pending' });
      };
      if (order && !order.isPaid) {
        if (!window.paypal) {
          // loadPaypalScript();
        }
      }
    }
  }, [code, errorPayPal, loadingPayPal, order, paypal, paypalDispatch]);

  async function alipay() {
    try {
      const res = await payOrder({
        orderId,
        matchCode,
      }).unwrap();
      toast.success(res.message);
      window.location.href = res.result;
      // window.open(res.result);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  }
  async function alipayMobile() {
    try {
      const res = await payOrderMobile({
        orderId,
        matchCode,
      }).unwrap();
      toast.success(res.message);
      // console.log(res.result);
      window.location.href = res.result;
      // window.open(res.result);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  }

  function onApprove(data, actions) {
    return actions.order.capture().then(async function (details) {
      try {
        await payOrder({ orderId, details });
        refetch();
        toast.success('Order is paid');
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    });
  }

  // TESTING ONLY! REMOVE BEFORE PRODUCTION
  // async function onApproveTest() {
  //   await payOrder({ orderId, details: { payer: {} } });
  //   refetch();

  //   toast.success('Order is paid');
  // }

  function onError(err) {
    toast.error(err.message);
  }

  function createOrder(data, actions) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: { value: order.totalPrice },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  }

  const deliverHandler = async () => {
    await deliverOrder(orderId);
    refetch();
  };
  const queryPaymentHandler = async () => {};
  return isLoading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error.data.message}</Message>
  ) : (
    <>
      <h1>
        {process.env.REACT_APP_CHINESE ? '订单号' : 'Order'} {order._id}
      </h1>
      <h1>
        {process.env.REACT_APP_CHINESE ? '下单时间' : 'order time'}{' '}
        {order.createdAt}
      </h1>
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>{process.env.REACT_APP_CHINESE ? '收货地址' : 'Shipping'}</h2>
              <p>
                <strong>
                  {process.env.REACT_APP_CHINESE ? '名字:' : 'Name:'}
                </strong>{' '}
                {order.user.name}
              </p>
              <p>
                <strong>Email: </strong>{' '}
                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              </p>
              <p>
                <strong>
                  {process.env.REACT_APP_CHINESE ? '地址：' : 'Address:'}
                </strong>
                {order.shippingAddress.address}, {order.shippingAddress.city} ,{' '}
                {order.shippingAddress.country},
                {order.shippingAddress.postalCode}
              </p>
              {order.isDelivered ? (
                <Message variant='success'>
                  Delivered on {order.deliveredAt.substring(0, 10)}
                </Message>
              ) : (
                <Message variant='danger'>Not Delivered</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>
                {process.env.REACT_APP_CHINESE ? '支付方式' : 'Payment Method'}
              </h2>
              <p>
                <strong>{order.paymentMethod}</strong>
              </p>
              {order.isPaid ? (
                <Message variant='success'>
                  Paid on {order.createdAt.substring(0, 10)}
                </Message>
              ) : (
                <Message variant='danger'>Not Paid</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>
                {process.env.REACT_APP_CHINESE ? '订单列表' : 'Order Items'}
              </h2>
              {order.orderItems.length === 0 ? (
                <Message>
                  {process.env.REACT_APP_CHINESE
                    ? '你的购物车为空'
                    : 'Order is empty'}
                </Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>
                  {process.env.REACT_APP_CHINESE ? '订单详情' : 'Order Summary'}
                </h2>
              </ListGroup.Item>
              {/* <ListGroup.Item>
                <Row>
                  <Col>Items</Col>
                  <Col>${order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Shipping</Col>
                  <Col>${order.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>
                  <Col>${order.taxPrice}</Col>
                </Row>
              </ListGroup.Item> */}
              <ListGroup.Item>
                <Row>
                  <Col>{process.env.REACT_APP_CHINESE ? '总价' : 'Total'}</Col>
                  <Col>
                    {process.env.REACT_APP_CHINESE ? '¥' : '$'}
                    {matchCode
                      ? (
                          Math.round(order.totalPrice * 100 * 0.95) / 100
                        ).toFixed(2)
                      : order.totalPrice}
                  </Col>
                </Row>
              </ListGroup.Item>
              {!order.isPaid && (
                <ListGroup.Item>
                  {loadingPay && <Loader />}

                  {isPending ? (
                    <Loader />
                  ) : (
                    <div>
                      {/* THIS BUTTON IS FOR TESTING! REMOVE BEFORE PRODUCTION! */}
                      {/* <Button
                        style={{ marginBottom: '10px' }}
                        onClick={onApproveTest}
                      >
                        Test Pay Order
                      </Button> */}

                      {/* <div>
                        <PayPalButtons
                          createOrder={createOrder}
                          onApprove={onApprove}
                          onError={onError}
                        ></PayPalButtons>
                      </div> */}
                      <Form>
                        <Form.Group className='my-2' controlId='code'>
                          <Form.Label>
                            {process.env.REACT_APP_CHINESE
                              ? '优惠码'
                              : 'promotion code'}
                          </Form.Label>
                          <Form.Control
                            className={matchCode ? 'border-success' : ''}
                            type='text'
                            placeholder='输入优惠码'
                            value={code}
                            required
                            onChange={(e) => setCode(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Form>
                      <ListGroup.Item>
                        <Row>
                          <Col className={styles.websitePayment}>
                            <Button onClick={alipay}>网页支付宝</Button>
                          </Col>
                          <Col className={styles.mobilePayment}>
                            <Button onClick={alipayMobile}>手机支付宝</Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    </div>
                  )}
                </ListGroup.Item>
              )}

              {loadingDeliver && <Loader />}

              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                !order.isDelivered && (
                  <ListGroup.Item>
                    <Button
                      type='button'
                      className='btn btn-block'
                      onClick={queryPaymentHandler}
                    >
                      Mark As Delivered
                    </Button>
                  </ListGroup.Item>
                )}
            </ListGroup>
          </Card>
          <Card>
            {order.isPaid && (
              <ListGroup>
                <ListGroup.Item>
                  <Row>
                    <Col>buyer_id</Col>
                    <Col>{order.paymentResultAlipay?.buyer_id}</Col>
                  </Row>
                  <Row>
                    <Col>trade_no</Col>
                    <Col>{order.paymentResultAlipay?.trade_no}</Col>
                  </Row>
                  <Row>
                    <Col>total_amount</Col>
                    <Col>{order.paymentResultAlipay?.total_amount}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button
                    type='button'
                    className='btn btn-block'
                    onClick={deliverHandler}
                  >
                    Query payment
                  </Button>
                </ListGroup.Item>
              </ListGroup>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderScreen;
