import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useGetCategoryQuery } from '../slices/productsApiSlice';
import Loader from '../components/Loader';

const NavbarCategory = ({ pages, page, category = '', keyword = '' }) => {
  const { data: categories, isLoading, error } = useGetCategoryQuery();

  return isLoading ? (
    <Loader />
  ) : (
    <Pagination className='d-flex '>
      <LinkContainer to='/'>
        <Pagination.Item>全部商品</Pagination.Item>
      </LinkContainer>

      {categories.map((c, index) => (
        <LinkContainer
          key={index}
          to={
            keyword ? `/category/${c}/search/${keyword}` : `/category/${c}`

            // !(pages > 1)
            //   ? keyword
            //     ? `/category/${category}/search/${keyword}`
            //     : `/category/${category}`
            //   : keyword
            //   ? `/category/${category}/search/${keyword}/page/${page}`
            //   : `/category/${category}/page/${page}`
          }
        >
          <Pagination.Item active={c === category}>{c}</Pagination.Item>
        </LinkContainer>
      ))}
    </Pagination>
  );
};
export default NavbarCategory;
